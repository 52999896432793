import { cx } from '@emotion/css'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/system'
import { array, string } from 'prop-types'
import { useState } from 'react'
import IconHeadArrowDown from '../../atoms/icons/arrowhead-down-icon'
import Typography from '../../atoms/typography'
import RichContent from '../../particles/rich-content'
import { AccordionStyled, ListItemStyled, ListStyled, NavLink, TypographyStyled, Wrapper } from './menus.styled'

const Menus = ({ className, menus, exclusive = true }) => {
	const theme = useTheme()
	const mdUp = useMediaQuery(theme.breakpoints.up('md'))

	const [expanded, setExpanded] = useState({})

	const handleChange = (panelId) => (event, isExpanded) => {
		setExpanded(exclusive ? { [panelId]: isExpanded } : { ...expanded, ...{ [panelId]: isExpanded } })
	}

	const renderMenu = (children) => {
		return children?.length > 0 ? (
			<ListStyled disablePadding>
				{children.map(({ title, level, cssClasses, type, url, target, children }, index) => {
					return (
						<ListItemStyled
							key={index}
							disableGutters
							disablePadding
							className={['menu-item', 'level-' + level, cssClasses].join(' ')}
						>
							<NavLink
								underline={type === 'group' ? 'none' : 'hover'}
								level={level}
								href={url ? url : {}}
								variant="body1"
								target={target}
							>
								<RichContent content={title} />
							</NavLink>

							{renderMenu(children)}
						</ListItemStyled>
					)
				})}
			</ListStyled>
		) : null
	}
	const accordionMenu = menus.map(({ title, children, cssClasses }, index) => ({
		title: (
			<Typography component="p" variant="footerTitle">
				{title}
			</Typography>
		),
		titleProps: {
			variant: 'h6',
		},
		id: 'accordion' + index,
		content: children ? renderMenu(children) : null,
		onChange: handleChange('accordion' + index),
		cssClasses: cssClasses,
	}))

	return (
		<Wrapper className={cx('Menus-root', className)}>
			{accordionMenu.map(({ title, titleProps, id, content, onChange, cssClasses }, index) => {
				return content ? (
					<AccordionStyled
						expanded={expanded[id] || mdUp}
						key={index}
						title={title}
						titleProps={titleProps}
						content={content}
						onChange={onChange}
						expandIcon={<IconHeadArrowDown fontSize="middle" />}
						className={cssClasses}
					/>
				) : (
					<TypographyStyled key={index} {...titleProps} className={cssClasses} component="div">
						{title}
					</TypographyStyled>
				)
			})}
		</Wrapper>
	)
}

Menus.propTypes = {
	/**
	 * Defines the footer menus array
	 */
	menus: array,
	/**
	 *  Defines className for root node
	 */
	className: string,
}

export default Menus
